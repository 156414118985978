var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',{staticClass:"d-flex flex-column primary darken-2 gap-4",class:_vm.$vuetify.breakpoint.smOnly ? '' : 'pa-4',attrs:{"handle":".drag-handler"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.controller.recruitNos),callback:function ($$v) {_vm.$set(_vm.controller, "recruitNos", $$v)},expression:"controller.recruitNos"}},[_vm._l((_vm.controller.recruitNos),function(recruitNo){return _c('div',{key:recruitNo.id,staticClass:"d-flex gap-2",class:_vm.$vuetify.breakpoint.smOnly ? '' : 'align-center'},[(!_vm.$vuetify.breakpoint.smOnly)?_c('v-icon',{staticClass:"cursor-pointer drag-handler",class:{
        'prevent-pointer-events': false,
      }},[_vm._v("mdi-drag")]):_vm._e(),_c('div',{staticClass:"d-flex gap-2 table-row flex-fill overflow-hidden",class:_vm.$vuetify.breakpoint.smOnly ? 'flex-column' : ''},[_c('div',{staticClass:"table-cell"},[_c('v-select-custom',{staticClass:"text-sm-body2 text-body5 required",attrs:{"outlined":"","dense":"","background-color":"primary darken-2","height":_vm.heightInput,"rule":_vm.$rules.required,"menu-props":{
            contentClass: 'selector-recruitment',
            offsetY: true,
          },"placeholder":"Plan","item-text":"groupName","item-value":"group","items":_vm.controller.teamGroups,"value":recruitNo.group,"rules":[_vm.$rules.required],"required":""},on:{"change":function($event){return _vm.controller.changeTeamGroup($event, recruitNo.id)}}})],1),_c('div',{staticClass:"table-cell"},[_c('v-select-custom',{staticClass:"text-sm-body2 text-body5",attrs:{"outlined":"","dense":"","background-color":"primary darken-2","height":_vm.heightInput,"menu-props":{
            contentClass: 'selector-recruitment',
            offsetY: true,
          },"placeholder":"UI/UX","item-text":"name","item-value":"uniqueName","items":recruitNo.categories,"value":recruitNo.selectedCategory,"item-disabled":"disabled","rules":[_vm.$rules.required],"required":""},on:{"change":function($event){return _vm.controller.changeTeamCategory($event, recruitNo.id)}}})],1)]),(_vm.showRecruitNumber)?_c('div',{staticClass:"recruitNumber"},[_c('v-text-field-custom',{staticClass:"text-sm-body2 text-body5 centered-input input-validator",attrs:{"background-color":"primary darken-1","height":_vm.heightInput,"min":1,"rules":[_vm.$rules.required, _vm.$rules.positiveInterger],"required":"","placeholder":"0"},on:{"keypress":function($event){return _vm.preventNegativeNumber($event)}},model:{value:(recruitNo.recruitNum),callback:function ($$v) {_vm.$set(recruitNo, "recruitNum", $$v)},expression:"recruitNo.recruitNum"}})],1):_vm._e(),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.controller.changeRecruitNos('remove', recruitNo.id)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-minus-circle")])],1)],1)],1)}),_c('v-btn',{staticClass:"align-self-end",attrs:{"rounded":"","outlined":""},on:{"click":function($event){return _vm.controller.changeRecruitNos('add')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Add")],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }